var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: { model: _vm.user, rules: _vm.rules, "label-width": "80px" },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "真实姓名", prop: "realName" } },
        [
          _c("el-input", {
            model: {
              value: _vm.user.realName,
              callback: function ($$v) {
                _vm.$set(_vm.user, "realName", $$v)
              },
              expression: "user.realName",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "手机号码", prop: "phone" } },
        [
          _c("el-input", {
            attrs: { maxlength: "11" },
            model: {
              value: _vm.user.phone,
              callback: function ($$v) {
                _vm.$set(_vm.user, "phone", $$v)
              },
              expression: "user.phone",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "邮箱", prop: "email" } },
        [
          _c("el-input", {
            attrs: { maxlength: "50" },
            model: {
              value: _vm.user.email,
              callback: function ($$v) {
                _vm.$set(_vm.user, "email", $$v)
              },
              expression: "user.email",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.submit },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "mini" },
              on: { click: _vm.close },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }