import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { updateUserInfo } from '@/api/user';
export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object
    }
  },
  data: function data() {
    return {
      // 表单校验
      rules: {
        realName: [{
          required: true,
          message: '真实姓名不能为空',
          trigger: 'blur'
        }],
        email: [{
          required: true,
          message: '邮箱地址不能为空',
          trigger: 'blur'
        }, {
          type: 'email',
          message: "'请输入正确的邮箱地址",
          trigger: ['blur', 'change']
        }],
        phone: [{
          required: true,
          message: '手机号码不能为空',
          trigger: 'blur'
        }, {
          pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
          message: '请输入正确的手机号码',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    submit: function submit() {
      var _this = this;
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          updateUserInfo(_this.user, _this.user.userId).then(function (response) {
            if (response.code === 200) {
              _this.$message({
                showClose: true,
                message: '修改成功',
                type: 'success'
              });
            } else {
              _this.$message({
                showClose: true,
                message: response.msg,
                type: 'error'
              });
            }
          });
        }
      });
    },
    close: function close() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push({
        path: '/index'
      });
    }
  }
};