//
//
//
//
//
//
//
//
//
//
//

var tooltip = ['x*y', function (x, y) {
  return {
    name: x,
    value: y
  };
}];
var scale = [{
  dataKey: 'x',
  min: 2
}, {
  dataKey: 'y',
  title: '时间',
  min: 1,
  max: 22
}];
export default {
  name: 'MiniArea',
  props: {
    data: {
      type: [Array],
      required: false,
      default: null
    }
  },
  data: function data() {
    return {
      // data:this.props.data,
      tooltip: tooltip,
      scale: scale,
      height: 100
    };
  }
};