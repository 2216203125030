import request from '@/utils/request';

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/new_login/captcha',
    method: 'get',
    headers: {
      "app": "x-admin"
    }
  });
}

// 发送手机验证码
export function sendCode(phone) {
  return request({
    url: '/new_login/sms',
    method: 'get',
    headers: {
      "app": "x-admin"
    },
    params: {
      phone: phone
    }
  });
}

// 手机验证码登录
export function loginByPhoneAndCode(data) {
  return request({
    url: '/new_login/phone',
    method: 'post',
    headers: {
      "app": "x-admin"
    },
    data: data
  });
}

// 获取二维码地址
/**
 * 
 * @param {type:2} data 
 * @returns 不传参获取微信二维码，传参获取企业微信二维码
 */
export function getQrInfo(type) {
  return request({
    url: '/new_login/qr?type=' + type,
    method: 'post',
    headers: {
      "app": "x-admin"
    }
  });
}

// 获取扫码结果
export function getQrResult(key) {
  return request({
    url: '/new_login/qr',
    method: 'get',
    headers: {
      "app": "x-admin"
    },
    params: {
      key: key
    }
  });
}

// 旧版本
export function getCodeImgOld() {
  return request({
    url: '/base/captcha',
    method: 'post'
  });
}