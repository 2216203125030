var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cell" }, [
    _c(
      "div",
      { staticClass: "cell-inner", class: _vm.border ? "border" : "" },
      [
        _c("div", { staticClass: "cell-item" }, [
          _c("div", { staticClass: "cell-item-label" }, [
            _vm.label
              ? _c("span", [_vm._v(" " + _vm._s(_vm.label) + " ")])
              : _c("span", [_vm._t("label")], 2),
          ]),
          _c("div", { staticClass: "cell-item-value" }, [
            _vm.value
              ? _c("span", [_vm._v(" " + _vm._s(_vm.value) + " ")])
              : _c("span", [_vm._t("value")], 2),
          ]),
        ]),
        _c("div", { staticClass: "cell-item mt5" }, [
          _vm.extra
            ? _c("span", [_vm._v(_vm._s(_vm.extra))])
            : _c("span", [_vm._t("extra")], 2),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }