import request from '@/utils/request';

// 获取左侧菜单
export function getRoutes() {
  return request({
    url: '/sys/accessSideBar',
    method: 'get'
  });
}

// 查询角色列表
export function listRole(query) {
  return request({
    url: '/role/list',
    method: 'get',
    params: query
  });
}

// 查询角色详细
export function getRole(roleId) {
  return request({
    url: '/role/get/' + roleId,
    method: 'get'
  });
}

// 新增角色
export function addRole(data) {
  return request({
    url: '/role/add',
    method: 'post',
    data: data
  });
}

// 修改角色
export function updateRole(data, roleId) {
  return request({
    url: '/role/edit/' + roleId,
    method: 'post',
    data: data
  });
}

// 角色状态修改
export function changeRoleStatus(roleId, data) {
  return updateRole(data, roleId);
}
export function getAllSysRoles() {
  return request({
    url: '/role/all',
    method: 'get'
  });
}