import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getCodeImg, sendCode, getQrInfo } from '@/api/login';
import moment from 'moment';
import SocialSign from './components/SocialSignin';
import QRCode from "qrcodejs2";
export default {
  name: 'Login',
  components: {
    SocialSign: SocialSign
  },
  data: function data() {
    var checkMobile = function checkMobile(rule, value, cb) {
      // 手机正则
      var regMobile = /^(0|86|17951)?(13[0-9]|15[0-9]|17[0-9]|18[0-9]|14[57])[0-9]{8}$/;
      if (regMobile.test(value)) {
        return cb();
      }
      cb(new Error('请输入正确的手机号'));
    };
    return {
      codeUrl: '',
      cookiePassword: '',
      refreshParticles: true,
      loginForm: {
        Account: '',
        Password: '',
        rememberMe: false,
        Captcha: '',
        Key: ''
      },
      loginRules: {
        Account: [{
          required: true,
          trigger: 'blur',
          message: '用户名不能为空'
        }],
        Password: [{
          required: true,
          trigger: 'blur',
          message: '密码不能为空'
        }],
        Captcha: [{
          required: true,
          trigger: 'change',
          message: '验证码不能为空'
        }]
      },
      phoneForm: {
        phone: '',
        code: ''
      },
      phoneRules: {
        phone: [{
          required: true,
          trigger: 'blur',
          message: '请输入手机号'
        }, {
          validator: checkMobile,
          trigger: ['blur', 'change']
        }],
        code: [{
          required: true,
          trigger: 'change',
          message: '验证码不能为空'
        }]
      },
      times: 60,
      preclick: true,
      reclick: false,
      qrLoading: false,
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      currentTime: null,
      activeName: 'first',
      formActive: 0,
      sysInfo: ''
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        var query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true
    }
  },
  created: function created() {
    this.getCode();
    // window.addEventListener('storage', this.afterQRScan)
    this.getCurrentTime();
  },
  mounted: function mounted() {
    var _this = this;
    if (this.loginForm.Account === '') {
      this.$refs.Account.focus();
    } else if (this.loginForm.Password === '') {
      this.$refs.Password.focus();
    }
    window.addEventListener('resize', function () {
      _this.refreshParticles = false;
      _this.$nextTick(function () {
        return _this.refreshParticles = true;
      });
    });
  },
  destroyed: function destroyed() {
    clearInterval(this.timer);
    window.removeEventListener('resize', function () {});
    // window.removeEventListener('storage', this.afterQRScan)
  },

  methods: {
    getCurrentTime: function getCurrentTime() {
      var _this2 = this;
      this.timer = setInterval(function (_) {
        _this2.currentTime = moment().format('YYYY-MM-DD HH时mm分ss秒');
      }, 1000);
    },
    getCode: function getCode() {
      var _this3 = this;
      getCodeImg().then(function (res) {
        if (res !== undefined) {
          _this3.codeUrl = res.data.base64;
          _this3.loginForm.Key = res.data.key;
        }
      });
    },
    checkCapslock: function checkCapslock() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        shiftKey = _ref.shiftKey,
        key = _ref.key;
      if (key && key.length === 1) {
        if (shiftKey && key >= 'a' && key <= 'z' || !shiftKey && key >= 'A' && key <= 'Z') {
          this.capsTooltip = true;
        } else {
          this.capsTooltip = false;
        }
      }
      if (key === 'CapsLock' && this.capsTooltip === true) {
        this.capsTooltip = false;
      }
    },
    showPwd: function showPwd() {
      var _this4 = this;
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(function () {
        _this4.$refs.Password.focus();
      });
    },
    handleLogin: function handleLogin(val) {
      var _this5 = this;
      switch (val) {
        case 0:
          this.$refs.loginForm.validate(function (valid) {
            if (valid) {
              _this5.loading = true;
              _this5.$store.dispatch('user/login', _this5.loginForm).then(function () {
                // 不刷新的时候用户菜单为上次登陆缓存结果
                location.reload();
                // this.$router
                //   .push({path: this.redirect || '/', query: this.otherQuery})
                //   .catch(() => {
                //   })
              }).catch(function () {
                _this5.loading = false;
                _this5.getCode();
              });
            } else {
              console.log('error submit!!');
              return false;
            }
          });
          break;
        case 1:
          this.$refs.phoneForm.validate(function (valid) {
            if (valid) {
              _this5.loading = true;
              _this5.$store.dispatch('user/loginByPhoneAndCode', _this5.phoneForm).then(function () {
                // 不刷新的时候用户菜单为上次登陆缓存结果
                location.reload();
              }).catch(function () {
                _this5.loading = false;
              });
            } else {
              console.log('error submit!!');
              return false;
            }
          });
          break;
      }
    },
    getOtherQuery: function getOtherQuery(query) {
      return Object.keys(query).reduce(function (acc, cur) {
        if (cur !== 'redirect') {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },
    handleClick: function handleClick(tab, event) {
      console.log(tab.index);
      this.formActive = Number(tab.index);
      switch (this.formActive) {
        case 1:
          this.formActive = 3;
          this.getQrCode(2);
          break;
        // case 2:
        //   this.getQrCode(1)
        //   break;
        // case 3:
        //   this.getQrCode(2)
        //   break;
      }
    },
    getQrCode: function getQrCode(val) {
      var _this6 = this;
      this.qrLoading = true;
      var type = '';
      if (val === 2) {
        //企业微信登录

        type = 2;
      }
      getQrInfo(type).then(function (res) {
        if (res.code === 0) {
          _this6.$refs.qrcode.innerHTML = '';
          _this6.qrcode(res.data.url);
          _this6.qrLoading = false;
          _this6.queryQrResult(res.data.key);
        }
      });
    },
    queryQrResult: function queryQrResult(key) {
      var _this7 = this;
      this.loading = true;
      var timer = setInterval(function () {
        console.log('进入定时器');
        _this7.$store.dispatch('user/getQrResult', key).then(function (res) {
          console.log(res);
          if (res.code === 0) {
            _this7.loading = false;
            clearInterval(timer);
            timer = null;
            // 不刷新的时候用户菜单为上次登陆缓存结果
            location.reload();
          } else if (res.code === 200 && res.msg === '请刷新更换新的二维码') {
            _this7.$message({
              showClose: true,
              duration: 0,
              message: '二维码已过期，请点击二维码刷新重新登陆',
              type: 'warning'
            });
            _this7.loading = false;
            clearInterval(timer);
            timer = null;
          }
        }).catch(function () {
          _this7.loading = false;
          clearInterval(timer);
          timer = null;
        });
      }, 5000);
    },
    qrcode: function qrcode(url) {
      var qrcode = new QRCode("qrcode", {
        width: 180,
        // 二维码宽度，单位像素
        height: 180,
        // 二维码高度，单位像素
        text: url
      });
    },
    onSendCode: function onSendCode() {
      var _this8 = this;
      console.log('onSendCode', this.phoneForm.phone);
      var TIME_COUNT = 60;
      this.$refs.phoneForm.validateField('phone', function (valid) {
        console.log('valid', valid);
        if (!valid) {
          _this8.preclick = false;
          _this8.reclick = false;
          _this8.times = TIME_COUNT;
          sendCode(_this8.phoneForm.phone);
          var timer = setInterval(function () {
            if (_this8.times > 0 && _this8.times <= TIME_COUNT) {
              _this8.times--;
            } else {
              _this8.reclick = true;
              clearInterval(timer);
              timer = null;
            }
          }, 1000);
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};