var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard-container" }, [
    _c(
      "div",
      { staticClass: "dashboard-editor-container" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 10 } },
          [
            _c(
              "el-col",
              { attrs: { sm: 24, md: 8 } },
              [
                _vm.serverInfo.cpu
                  ? _c("el-card", { staticClass: "box-card" }, [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [_c("span", [_vm._v("CPU使用率")])]
                      ),
                      _c("div", { staticClass: "monitor" }, [
                        _c(
                          "div",
                          { staticClass: "monitor-header" },
                          [
                            _c("el-progress", {
                              attrs: {
                                color: _vm.$store.state.settings.theme,
                                type: "circle",
                                percentage: _vm.serverInfo.cpu.Percent,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "monitor-footer" },
                          [
                            _c("Cell", {
                              attrs: {
                                label: "CPU主频",
                                value:
                                  _vm.serverInfo.cpu.cpuInfo[0].modelName.split(
                                    "@ "
                                  )[1],
                                border: "",
                              },
                            }),
                            _c("Cell", {
                              attrs: {
                                label: "核心数",
                                value: "" + _vm.serverInfo.cpu.cpuInfo[0].cores,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "el-col",
              { attrs: { sm: 24, md: 8 } },
              [
                _vm.serverInfo.mem
                  ? _c("el-card", { staticClass: "box-card" }, [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [_c("span", [_vm._v("内存使用率")])]
                      ),
                      _c("div", { staticClass: "monitor" }, [
                        _c(
                          "div",
                          { staticClass: "monitor-header" },
                          [
                            _c("el-progress", {
                              attrs: {
                                color: _vm.$store.state.settings.theme,
                                type: "circle",
                                percentage: _vm.serverInfo.mem.usage,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "monitor-footer" },
                          [
                            _c("Cell", {
                              attrs: {
                                label: "总内存",
                                value: _vm.serverInfo.mem.total + "G",
                                border: "",
                              },
                            }),
                            _c("Cell", {
                              attrs: {
                                label: "已用内存",
                                value: _vm.serverInfo.mem.used + "G",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "el-col",
              { attrs: { sm: 24, md: 8 } },
              [
                _vm.serverInfo.disk
                  ? _c("el-card", { staticClass: "box-card" }, [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [_c("span", [_vm._v("磁盘信息")])]
                      ),
                      _c("div", { staticClass: "monitor" }, [
                        _c(
                          "div",
                          { staticClass: "monitor-header" },
                          [
                            _c("el-progress", {
                              attrs: {
                                color: _vm.$store.state.settings.theme,
                                type: "circle",
                                percentage: Number(
                                  (
                                    (_vm.serverInfo.disk.free /
                                      _vm.serverInfo.disk.total) *
                                    100
                                  ).toFixed(2)
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "monitor-footer" },
                          [
                            _c("Cell", {
                              attrs: {
                                label: "总内存",
                                value: _vm.serverInfo.disk.total + "G",
                                border: "",
                              },
                            }),
                            _c("Cell", {
                              attrs: {
                                label: "已用内存",
                                value: _vm.serverInfo.disk.free + "G",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-row",
          { staticStyle: { "margin-top": "20px" }, attrs: { gutter: 12 } },
          [
            _c(
              "el-col",
              {
                style: { marginBottom: "12px" },
                attrs: { sm: 24, xs: 24, md: 6, xl: 6, lg: 6 },
              },
              [
                _c(
                  "chart-card",
                  { attrs: { title: "总销售额", total: "￥126,560" } },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          slot: "action",
                          effect: "dark",
                          content: "指标说明",
                          placement: "top-start",
                        },
                        slot: "action",
                      },
                      [_c("i", { staticClass: "el-icon-warning-outline" })]
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "trend",
                          {
                            staticStyle: { "margin-right": "16px" },
                            attrs: { flag: "top", rate: "12" },
                          },
                          [
                            _c(
                              "span",
                              { attrs: { slot: "term" }, slot: "term" },
                              [_vm._v("周同比")]
                            ),
                          ]
                        ),
                        _c("trend", { attrs: { flag: "bottom", rate: "11" } }, [
                          _c(
                            "span",
                            { attrs: { slot: "term" }, slot: "term" },
                            [_vm._v("日同比")]
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c("template", { slot: "footer" }, [
                      _vm._v("日均销售额"),
                      _c("span", [_vm._v("￥ 234.56")]),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "el-col",
              {
                style: { marginBottom: "12px" },
                attrs: { sm: 24, xs: 24, md: 6, xl: 6, lg: 6 },
              },
              [
                _c(
                  "chart-card",
                  { attrs: { title: "访问量", total: 8846 } },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          slot: "action",
                          effect: "dark",
                          content: "指标说明",
                          placement: "top-start",
                        },
                        slot: "action",
                      },
                      [_c("i", { staticClass: "el-icon-warning-outline" })]
                    ),
                    _c(
                      "div",
                      [_c("mini-area", { attrs: { data: _vm.miniData } })],
                      1
                    ),
                    _c("template", { slot: "footer" }, [
                      _vm._v("日访问量"),
                      _c("span", [_vm._v(" " + _vm._s("1234"))]),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "el-col",
              {
                style: { marginBottom: "12px" },
                attrs: { sm: 24, xs: 24, md: 6, xl: 6, lg: 6 },
              },
              [
                _c(
                  "chart-card",
                  { attrs: { title: "支付笔数", total: 6560 } },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          slot: "action",
                          effect: "dark",
                          content: "指标说明",
                          placement: "top-start",
                        },
                        slot: "action",
                      },
                      [_c("i", { staticClass: "el-icon-warning-outline" })]
                    ),
                    _c("div", [_c("mini-bar")], 1),
                    _c("template", { slot: "footer" }, [
                      _vm._v("转化率 "),
                      _c("span", [_vm._v("60%")]),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "el-col",
              {
                style: { marginBottom: "12px" },
                attrs: { sm: 24, xs: 24, md: 6, xl: 6, lg: 6 },
              },
              [
                _c(
                  "chart-card",
                  { attrs: { title: "运营活动效果", total: "78%" } },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          slot: "action",
                          effect: "dark",
                          content: "指标说明",
                          placement: "top-start",
                        },
                        slot: "action",
                      },
                      [_c("i", { staticClass: "el-icon-warning-outline" })]
                    ),
                    _c(
                      "div",
                      [
                        _c("mini-progress", {
                          attrs: {
                            color: "rgb(19, 194, 194)",
                            target: 80,
                            percentage: 78,
                            height: "8px",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "template",
                      { slot: "footer" },
                      [
                        _c(
                          "trend",
                          {
                            staticStyle: { "margin-right": "16px" },
                            attrs: { flag: "top", rate: "12" },
                          },
                          [
                            _c(
                              "span",
                              { attrs: { slot: "term" }, slot: "term" },
                              [_vm._v("同周比")]
                            ),
                          ]
                        ),
                        _c("trend", { attrs: { flag: "bottom", rate: "80" } }, [
                          _c(
                            "span",
                            { attrs: { slot: "term" }, slot: "term" },
                            [_vm._v("日环比")]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }