//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import userAvatar from './userAvatar';
import userInfo from './userInfo';
import resetPwd from './resetPwd';
import { getInfo } from '@/api/user';
export default {
  name: 'Profile',
  components: {
    userAvatar: userAvatar,
    userInfo: userInfo,
    resetPwd: resetPwd
  },
  data: function data() {
    return {
      user: {
        userId: 0,
        userName: undefined,
        realName: undefined,
        email: undefined,
        phone: undefined,
        avatar: undefined
      },
      roleGroup: {},
      activeTab: 'userinfo'
    };
  },
  created: function created() {
    this.getUser();
  },
  methods: {
    getUser: function getUser() {
      var _this = this;
      getInfo().then(function (response) {
        _this.user.userId = response.data.userId;
        _this.user.userName = response.data.userName;
        _this.user.realName = response.data.realName;
        _this.user.email = response.data.email;
        _this.user.phone = response.data.phone;
        _this.user.avatar = response.data.avatar;
        _this.roleGroup = response.data.roles;
      });
    }
  }
};