import request from '@/utils/request';
export function login(data) {
  return request({
    url: '/new_login/password',
    method: 'post',
    data: data,
    headers: {
      "app": "x-admin"
    }
  });
}
export function getInfo() {
  return request({
    url: '/sys/profile',
    method: 'get'
  });
}

// 查询用户列表
export function listUser(query) {
  return request({
    url: '/user/list',
    method: 'get',
    params: query
  });
}

// 查询用户详情
export function getUser(userId) {
  return request({
    url: '/user/get/' + userId,
    method: 'get'
  });
}

// 新增用户
export function addUser(data) {
  return request({
    url: '/user/add',
    method: 'post',
    data: data
  });
}

// 修改用户
export function updateUser(data, userId) {
  return request({
    url: '/user/edit/' + userId,
    method: 'put',
    data: data
  });
}

// 修改用户
export function updateUserInfo(data, userId) {
  return request({
    url: '/sys/editInfo/' + userId,
    method: 'put',
    data: data
  });
}

// 用户状态修改
export function changeUserStatus(userId, status) {
  var data = {
    userId: userId,
    status: status
  };
  return updateUser(data, userId);
}
export function updateUserPwd(oldPassword, newPassword) {
  var data = {
    oldPassword: oldPassword,
    newPassword: newPassword
  };
  return request({
    url: '/sys/pwd',
    method: 'put',
    data: data
  });
}

// 用户头像上传
export function uploadAvatar(data) {
  return request({
    url: '/sys/avatar',
    method: 'post',
    data: data
  });
}
export function logout() {
  return request({
    url: '/base/logout',
    method: 'post'
  });
}