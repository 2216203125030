import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
//
//
//
//
//
//
//

export default {
  name: 'App',
  data: function data() {
    return {
      loading: false,
      isMobile: false
    };
  },
  beforeCreate: function beforeCreate() {
    console.log("进了app.vue");
    // this.isEquipment()
    // this.loading = this.isMobile

    var flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    if (flag) {
      //如果flag不为空，说明可以匹配到，是手机端
      this.loading = true;
    }
  },
  methods: {
    isEquipment: function isEquipment() {
      var flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      if (flag) {
        //如果flag不为空，说明可以匹配到，是手机端
        this.isMobile = true;
      }
    }
  }
};