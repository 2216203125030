import request from '@/utils/request';

// 查询列表
export function listMessage(query) {
  return request({
    url: '/message/list',
    method: 'get',
    params: query
  });
}

// 获取推送消息
export function getPushMessage(query) {
  return request({
    url: '/message/getPushMessage',
    method: 'get',
    params: query
  });
}

// 获取详情
export function getMessage(id) {
  return request({
    url: '/message/get?id=' + id,
    method: 'get'
  });
}