var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      staticClass: "right-menu-item",
      attrs: {
        placement: "left-start",
        title: "标题",
        width: "600",
        trigger: "hover",
      },
    },
    [
      _c(
        "el-table",
        { attrs: { data: _vm.messageList } },
        [
          _c("el-table-column", {
            attrs: { width: "150", property: "title", label: "标题" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.location.length > 0
                      ? _c(
                          "span",
                          {
                            staticClass: "title-location",
                            attrs: { target: "_blank" },
                            on: {
                              click: function ($event) {
                                return _vm.messageDispose(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.title))]
                        )
                      : _vm._e(),
                    scope.row.location.length <= 0
                      ? _c("span", [_vm._v(_vm._s(scope.row.title))])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "100", property: "sender", label: "发布人" },
          }),
          _c("el-table-column", {
            attrs: { width: "300", property: "createdAt", label: "发布时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.parseTime(scope.row.created))),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-button",
        {
          staticStyle: {
            "margin-top": "5px",
            "margin-right": "10px",
            "font-size": "20px",
            position: "relative",
          },
          attrs: {
            slot: "reference",
            type: "text",
            icon: "el-icon-message-solid",
          },
          slot: "reference",
        },
        [
          _c(
            "span",
            {
              staticStyle: {
                "min-width": "16px",
                "text-align": "center",
                height: "16px",
                padding: "0 2px",
                "line-height": "16px",
                "font-size": "12px",
                color: "#fff",
                background: "red",
                "border-radius": "4px",
                position: "absolute",
                top: "5px",
                left: "16px",
              },
            },
            [_vm._v(" " + _vm._s(_vm.total <= 99 ? _vm.total : "99+") + " ")]
          ),
          _c("audio", {
            ref: "audio",
            attrs: {
              controls: "controls",
              hidden: "",
              src: "message/message_alert.wav",
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }