var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _c(
        "div",
        { attrs: { id: "particles-js" } },
        [
          _vm.refreshParticles
            ? _c("vue-particles", {
                attrs: {
                  color: "#dedede",
                  "particle-opacity": 0.7,
                  "particles-number": 80,
                  "shape-type": "circle",
                  "particle-size": 4,
                  "lines-color": "#dedede",
                  "lines-width": 1,
                  "line-linked": true,
                  "line-opacity": 0.4,
                  "lines-distance": 150,
                  "move-speed": 3,
                  "hover-effect": true,
                  "hover-mode": "grab",
                  "click-effect": true,
                  "click-mode": "push",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "login-weaper animated bounceInDown" }, [
        _vm._m(0),
        _c("div", { staticClass: "login-border" }, [
          _c(
            "div",
            { staticClass: "login-main" },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "密码登陆", name: "first" } },
                    [
                      _vm.formActive === 0
                        ? _c(
                            "el-form",
                            {
                              ref: "loginForm",
                              staticClass: "login-form",
                              attrs: {
                                model: _vm.loginForm,
                                rules: _vm.loginRules,
                                autocomplete: "on",
                                "label-position": "left",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "Account" } },
                                [
                                  _c("span", { staticClass: "svg-container" }, [
                                    _c("i", { staticClass: "el-icon-user" }),
                                  ]),
                                  _c("el-input", {
                                    ref: "Account",
                                    attrs: {
                                      placeholder: "用户名",
                                      name: "Account",
                                      type: "text",
                                      size: "small",
                                      tabindex: "1",
                                      autocomplete: "on",
                                    },
                                    model: {
                                      value: _vm.loginForm.Account,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.loginForm, "Account", $$v)
                                      },
                                      expression: "loginForm.Account",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content: "Caps lock is On",
                                    placement: "right",
                                    manual: "",
                                  },
                                  model: {
                                    value: _vm.capsTooltip,
                                    callback: function ($$v) {
                                      _vm.capsTooltip = $$v
                                    },
                                    expression: "capsTooltip",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "Password" } },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "svg-container" },
                                        [
                                          _c("svg-icon", {
                                            attrs: { "icon-class": "password" },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("el-input", {
                                        key: _vm.passwordType,
                                        ref: "Password",
                                        attrs: {
                                          size: "small",
                                          type: _vm.passwordType,
                                          placeholder: "密码",
                                          name: "Password",
                                          tabindex: "2",
                                          autocomplete: "on",
                                        },
                                        on: {
                                          blur: function ($event) {
                                            _vm.capsTooltip = false
                                          },
                                        },
                                        nativeOn: {
                                          keyup: [
                                            function ($event) {
                                              return _vm.checkCapslock($event)
                                            },
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              ) {
                                                return null
                                              }
                                              return _vm.handleLogin(0)
                                            },
                                          ],
                                        },
                                        model: {
                                          value: _vm.loginForm.Password,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.loginForm,
                                              "Password",
                                              $$v
                                            )
                                          },
                                          expression: "loginForm.Password",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "show-pwd",
                                          on: { click: _vm.showPwd },
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              "icon-class":
                                                _vm.passwordType === "password"
                                                  ? "eye"
                                                  : "eye-open",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { width: "55%", float: "left" },
                                  attrs: { prop: "code" },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "svg-container" },
                                    [
                                      _c("svg-icon", {
                                        attrs: { "icon-class": "validCode" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("el-input", {
                                    ref: "Captcha",
                                    staticStyle: { width: "60%" },
                                    attrs: {
                                      placeholder: "验证码",
                                      name: "Captcha",
                                      type: "text",
                                      tabindex: "3",
                                      maxlength: "5",
                                      autocomplete: "off",
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.handleLogin(0)
                                      },
                                    },
                                    model: {
                                      value: _vm.loginForm.Captcha,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.loginForm, "Captcha", $$v)
                                      },
                                      expression: "loginForm.Captcha",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "login-code",
                                  staticStyle: {
                                    cursor: "pointer",
                                    width: "40%",
                                    height: "40px",
                                    float: "right",
                                    "background-color": "#f0f1f5",
                                  },
                                },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      height: "40px",
                                      width: "100%",
                                      border: "1px solid rgba(0, 0, 0, 0.1)",
                                      "border-radius": "5px",
                                    },
                                    attrs: { src: _vm.codeUrl },
                                    on: { click: _vm.getCode },
                                  }),
                                ]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    padding: "12px 20px",
                                    "margin-bottom": "30px",
                                    "letter-spacing": "6px",
                                  },
                                  attrs: {
                                    loading: _vm.loading,
                                    type: "primary",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.handleLogin(0)
                                    },
                                  },
                                },
                                [
                                  !_vm.loading
                                    ? _c("span", [_vm._v("登 录")])
                                    : _c("span", [_vm._v("登 录 中...")]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "微信登录", name: "fourth" } },
                    [
                      _vm.formActive === 3
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.qrLoading,
                                  expression: "qrLoading",
                                },
                              ],
                              staticClass: "login-qr",
                            },
                            [
                              _c("div", {
                                ref: "qrcode",
                                attrs: { id: "qrcode" },
                                on: {
                                  click: function ($event) {
                                    return _vm.getQrCode(2)
                                  },
                                },
                              }),
                              _c("span", [_vm._v("使用企业微信扫码登录")]),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: { title: "Or connect with", visible: _vm.showDialog },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _vm._v(
            " Can not be simulated on local, so please combine you own business simulation! ! ! "
          ),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("social-sign"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login-left" }, [
      _c("img", {
        staticClass: "img",
        attrs: {
          src: "https://test.facejournal.cn/tm_5bac8a02d483197a5b80198800652be3_20220325163711.png",
          alt: "",
        },
      }),
      _c("p", { staticClass: "title" }, [_vm._v("国高集团OA系统")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }