var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "antv-chart-mini" }, [
    _c(
      "div",
      { staticClass: "chart-wrapper", style: { height: 46 } },
      [
        _c(
          "v-chart",
          {
            attrs: {
              "force-fit": true,
              height: _vm.height,
              data: _vm.data,
              padding: [36, 5, 18, 5],
            },
          },
          [_c("v-tooltip"), _c("v-bar", { attrs: { position: "x*y" } })],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }