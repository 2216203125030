//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import store from '@/store';
import { VueCropper } from 'vue-cropper';
import { uploadAvatar } from '@/api/user';
export default {
  components: {
    VueCropper: VueCropper
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object
    }
  },
  data: function data() {
    return {
      // 是否显示弹出层
      open: false,
      // 弹出层标题
      title: '修改头像',
      options: {
        img: store.getters.avatar,
        // 裁剪图片的地址
        autoCrop: true,
        // 是否默认生成截图框
        autoCropWidth: 200,
        // 默认生成截图框宽度
        autoCropHeight: 200,
        // 默认生成截图框高度
        fixedBox: true // 固定截图框大小 不允许改变
      },

      previews: {}
    };
  },
  methods: {
    // 编辑头像
    editCropper: function editCropper() {
      this.open = true;
    },
    // 覆盖默认的上传行为
    requestUpload: function requestUpload() {},
    // 向左旋转
    rotateLeft: function rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    // 向右旋转
    rotateRight: function rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    // 图片缩放
    changeScale: function changeScale(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    // 上传预处理
    beforeUpload: function beforeUpload(file) {
      var _this = this;
      if (file.type.indexOf('image/') === -1) {
        this.$message('文件格式错误，请上传图片类型,如：JPG，PNG后缀的文件。');
      } else {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          _this.options.img = reader.result;
        };
      }
    },
    // 上传图片
    uploadImg: function uploadImg() {
      var _this2 = this;
      this.$refs.cropper.getCropBlob(function (data) {
        var formData = new FormData();
        formData.append('upload[]', data);
        uploadAvatar(formData).then(function (response) {
          if (response.code === 200) {
            _this2.open = false;
            // this.options.img = process.env.VUE_APP_BASE_API + response.data
            _this2.options.img = response.avatar;
            _this2.$message({
              showClose: true,
              message: '修改成功',
              type: 'success'
            });
          } else {
            _this2.$message({
              showClose: true,
              message: response.msg,
              type: 'error'
            });
          }
          _this2.$refs.cropper.clearCrop();
        });
      });
    },
    // 实时预览
    realTime: function realTime(data) {
      this.previews = data;
    }
  }
};