//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Trend',
  props: {
    rate: {
      type: String,
      default: '',
      required: true
    },
    flag: {
      type: String,
      default: '',
      required: true
    }
  }
};