//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: '国高管理平台',
      logo: 'https://test.facejournal.cn/tm_df2e03b7928bdcaf4cefb7ca2b630c73_20220325163539.png'
    };
  }
};