var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chart-trend" },
    [
      _vm._t("term"),
      _c("span", [_vm._v(_vm._s(_vm.rate) + "%")]),
      _c("span", { class: [_vm.flag] }, [
        _c("i", { class: "el-icon-caret-" + _vm.flag }),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }