import "core-js/modules/es.number.to-fixed.js";
import Vue from 'vue';
export function tabledata(val, row, name, title) {
  var str = val.split('.');
  var str1 = eval('row.' + str[0]);
  if (str.length == 1) {
    //点出第一个为字符串
    return str1;
  } else if (str.length == 2) {
    //点出第一个为数组 [{'0','0'}]
    if (str1 instanceof Array) {
      var order = '';
      for (var _i = 0; _i < str1.length; _i++) {
        if (name == '支付总额' || name == '协议总额' || name == '原价总额') {
          var payno = eval('str1[' + _i + '].' + str[1]);
          order = order + parseFloat(payno / 100).toFixed(2) + '<br>';
        } else if (name == '是否支付完成' || name == '是否发送用户(法大大)' || name == '是否签署(法大大)' || name == '是否发送给用户' || name == '通知展示' || name == '说明展示' || name == '是否签署' || name == '是否生成协议' || name == '是否升级') {
          order = order + (eval('str1[' + _i + '].' + str[1]) ? '是' : '否') + '<br>';
        } else if (name == '订单类型') {
          order = order + (eval('str1[' + _i + '].' + str[1]) == 1 ? '普通订单' : '后付款订单') + '<br>';
        } else if (name == '签署时间(法大大)' || name == '协议过期时间' || name == '签署时间' || name == '创建时间' || name == '最后支付时间') {
          // let timestr = eval('str1['+i+'].'+str[1]).substring (0,4)
          // let timedata = timestr<1000?'': Vue.prototype.parseTime(eval('str1['+i+'].'+str[1]))
          var timedata = Vue.prototype.parseTime(eval('str1[' + _i + '].' + str[1]));
          order = order + timedata + '<br>';
        } else if (name == '订单状态') {
          order = order + (eval('str1[' + _i + '].' + str[1]) == 10 || eval('str1[' + _i + '].' + str[1]) == 20 ? '已驳回' : eval('str1[' + _i + '].' + str[1]) == 11 ? '待审核' : eval('str1[' + _i + '].' + str[1]) >= 12 && eval('str1[' + _i + '].' + str[1]) != 20 ? '已通过' : '') + '<br>';
        } else {
          order = order + eval('str1[' + _i + '].' + str[1]) + '<br>';
        }
      }
      return order;
    } else {
      //点出第一个为对象 {'.','.'}
      var strArr2 = eval('str1.' + str[1]);
      if (strArr2 instanceof Array) {
        var strinArr = '';
        for (var i = 0; i < strArr2.length; i++) {
          strinArr = strinArr + strArr2[i] + '<br>';
        }
        return strinArr;
      } else {
        if (name == '订单状态') {
          return (eval('row.' + str[0] + '.' + str[1]) == 10 || eval('row.' + str[0] + '.' + str[1]) == 20 ? '已驳回' : eval('row.' + str[0] + '.' + str[1]) == 11 ? '待审核' : eval('row.' + str[0] + '.' + str[1]) >= 12 && eval('row.' + str[0] + '.' + str[1]) != 20 ? '已通过' : '') + '<br>';
        } else if (name == '是否老用户' || name == '说明展示' || name == '是否签署' || name == '是否生成协议' || name == '是否升级' || name == '通知展示' || name == '是否签署(法大大)' || name == '是否报告' || name == '是否预付款') {
          return eval('row.' + str[0] + '.' + str[1]) == 0 ? '否' : '是';
        } else if (name == '签署时间(法大大)' || name == '过期时间(法大大)' || name == '老客户成交时间' || name == '客户进来时间' || name == '签署时间' || name == ' 最后支付时间' || name == '创建时间' || name == '协议过期时间') {
          // let timestr = eval('row.'+str[0]+'.'+str[1]).substring (0,4)
          // console.log(timestr)
          return Vue.prototype.parseTime(eval('row.' + str[0] + '.' + str[1]));
        } else if (name == '订单类型') {
          if (row.order.is_upgrade) {
            return '升级订单';
          } else {
            return eval('row.' + str[0] + '.' + str[1]) == 1 ? '普通订单' : '后付款订单';
          }
        } else if (name == '是否发送给用户' || name == '是否发送用户(法大大)' || name == '是否明细' || name == '是否支付完成') {
          return eval('row.' + str[0] + '.' + str[1]) ? '是' : '否';
        } else if (name == '协议总额' || name == '支付总额' || name == '原价总额' || name == '退款金额' || name == '支付总额统计' || name == '开票总额统计' || name == '预付款金额' || name == '预付款赠送金额' || name == '预付款剩余金额') {
          var _payno = eval('row.' + str[0] + '.' + str[1]);
          return parseFloat(_payno / 100).toFixed(2) + '<br>';
        } else if (name == '开票类型') {
          return eval('row.' + str[0] + '.' + str[1]) == 1 ? '电子发票' : '纸质发票';
        } else if (name == '开票抬头') {
          return eval('row.' + str[0] + '.' + str[1]) == 1 ? '公司' : '个人';
        } else if (name == '开票状态') {
          if (title == '审核发票') {
            return eval('row.' + str[0] + '.' + str[1]) == 40 ? '已驳回' : eval('row.' + str[0] + '.' + str[1]) >= 41 ? '已通过' : '待审核';
          } else if (title == '开票管理') {
            return eval('row.' + str[0] + '.' + str[1]) == 50 ? '已驳回' : eval('row.' + str[0] + '.' + str[1]) > 50 ? '已开票' : '待开票';
          }
        } else if (name == '退款状态') {
          return (eval('row.' + str[0] + '.' + str[1]) == 70 ? '已驳回' : eval('row.' + str[0] + '.' + str[1]) == 71 ? '待退款' : eval('row.' + str[0] + '.' + str[1]) == 72 ? '已退款' : '') + '<br>';
        } else if (name == '是否连号') {
          return eval('row.' + str[0] + '.' + str[1]) == 0 ? '不连号' : '连号';
        } else {
          return eval('row.' + str[0] + '.' + str[1]);
        }
      }
    }
  } else if (str.length == 3) {
    //user.user_consultants.real_name
    if (str1 instanceof Array) {
      //点出第一个为数组、第二个为数组[{'','.','.',[]}]
      var len3 = '';
      for (var _i2 = 0; _i2 < str1.length; _i2++) {
        var str1_2 = eval('str1[' + _i2 + '].' + str[1]);
        if (str1_2.length > 0) {
          if (str1_2 instanceof Array) {
            for (var _j = 0; _j < str1_2.length; _j++) {
              if (str1_2[_j] != undefined) {
                if (name == '协议价' || name == '原价' || name == '产品协议价' || name == '产品原价' || name == '支付金额') {
                  var _payno2 = eval('str1_2[' + _j + '].' + str[2]);
                  len3 = len3 + parseFloat(_payno2 / 100).toFixed(2) + '<br>';
                } else if (name == '训练营开课时间') {
                  // let timestr = eval('str1_2['+j+'].' +str[2]).substring (0,4)
                  // let timedata = timestr<1000?'':Vue.prototype.parseTime(eval('str1_2['+j+'].' +str[2]))
                  var _timedata = Vue.prototype.parseTime(eval('str1_2[' + _j + '].' + str[2]));
                  len3 = len3 + _timedata + '<br>';
                } else {
                  len3 = len3 + eval('str1_2[' + _j + '].' + str[2]) + '<br>';
                }
              }
            }
          }
        }
      }
      return len3;
    } else {
      //点出第一个为字符串、第二个为数组{'','.','.',[]}
      var str2 = eval('row.' + str[0] + '.' + str[1]);
      if (str2 instanceof Array) {
        var temp = '';
        for (var _i3 = 0; _i3 < str2.length; _i3++) {
          if (name == '金额' || name == '折扣金额' || name == '协议价' || name == '原价' || name == '支付金额' || name == '顾问分成' || name == '产品原价' || name == '产品协议价' || name == '赠品价值') {
            var _payno3 = eval('str2[' + _i3 + '].' + str[2]);
            temp = temp + parseFloat(_payno3 / 100).toFixed(2) + '<br>';
          } else if (name == '是否折扣') {
            return eval('str2[' + _i3 + '].' + str[2]) == 0 ? '否' : '是';
          } else if (name == '训练营开课时间' || name == '支付时间') {
            // let timestr = eval('str2['+i+'].' +str[2]).substring (0,4)
            // return timestr<1000?'':Vue.prototype.parseTime(eval('str2['+i+'].' +str[2])) + '<br>'
            return Vue.prototype.parseTime(eval('str2[' + _i3 + '].' + str[2])) + '<br>';
          } else {
            temp = temp + eval('str2[' + _i3 + '].' + str[2]) + '<br>';
          }
        }
        return temp;
      }
    }
  } else if (str.length == 4) {
    //点出第一个为字符串，点出第二个为数组，点出第三个为数组，点出第三个为字符串{[{'.','.',[{}]}]}
    var _str = eval('row.' + str[0] + '.' + str[1]);
    if (_str instanceof Array) {
      var len4 = '';
      for (var _i4 = 0; _i4 < _str.length; _i4++) {
        var str3 = _str[_i4].consultants;
        for (var j = 0; j < str3.length; j++) {
          if (name == '课程顾问') {
            len4 = str3[j].real_name + '<br>';
          } else if (name == '顾问业绩') {
            var _payno4 = str3[j].consultant_per;
            len4 = parseFloat(_payno4 / 100).toFixed(2) + '<br>';
          }
        }
      }
      return len4;
    }
  }
}